// 基础组件全局管理
import CommonHeader from "./CommonHeader";
import CommonModel from "./CommonModel";
import CommonBreadcrumb from "./CommonBreadcrumb";
import FilterTime from "./FilterTime";
import ZSearch from "./ZSearch";
import GSearch from "./GSearch";
import ZInputTrimStart from "./ZInputTrimStart";

function baseComponents(Vue) {
    Vue.component('CommonHeader', CommonHeader);
    Vue.component('CommonModel', CommonModel);
    Vue.component('CommonBreadcrumb', CommonBreadcrumb);
    Vue.component('FilterTime', FilterTime);
    Vue.component('ZSearch', ZSearch);
    Vue.component('GSearch', GSearch);
    Vue.component('ZInputTrimStart', ZInputTrimStart);
}

export default baseComponents
