<template>
    <Input v-model="value" v-bind="$attrs" @input="inputValue"/>
</template>

<script>
    export default {
        name: "index",
        model: {
            prop: 'modelValue',// value名
            event: 'input',// 子组件要更新父组件值需要注册的方法
        },
        props: {
            modelValue: {
                type: String,
                default: ''
            },
            width: {
                type: String,
                default: '100%'
            }
        },
        data() {
            return {
                value: this.modelValue
            }
        },
        watch: {
            modelValue(newVal) {
                this.value = newVal;
            }
        },
        methods: {
            inputValue(event) {
                this.$nextTick(() => {
                    this.value = event.replace(/^\s+/, '');
                    this.$emit('input', this.value);
                    this.$emit('change', this.value);
                })
            }
        }
    }
</script>